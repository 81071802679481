import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotifiersService {
    private updateProfilImg = new Subject<any>();
    private updateLogoImg = new Subject<any>();
    private updateFolderList = new Subject<any>();
    private updateFolderSelected = new Subject<any>();
    private updateLanguage = new Subject<any>();
    private updateProfile = new Subject<any>();
    private updateClientAdmin = new Subject<any>();
    private deleteFolder = new Subject<any>();
    private notifyEmptyFolder = new Subject<any>();
    private notifyAddressChanges = new Subject<any>();
    private importClients = new Subject<any>();
    private importProducts = new Subject<any>();
    private connectWebSocket = new Subject<any>();
    private updateDataTable = new Subject<any>();
    private finishedImport = new Subject<any>();
    private notifyChangePermissions = new Subject<any>();
    private sidebarOpened = new Subject<any>();
    private adminLogoRedirection = new Subject<any>();
    private newFolderNotifier = new Subject<any>();
    private renameFolderNotifier = new Subject<any>();
    private refreshProductsNotifier = new Subject<any>();
    private refreshClientNotifier = new Subject<any>();
    private refreshPriceListNotifier = new Subject<any>();
    private refreshFamilyCientNotifier = new Subject<any>();
    private refreshDeliveryNoteNotifier = new Subject<any>();
    private productCreatedNotifier = new Subject<any>();
    private changeAssociateDocsNotifier = new Subject<any>();
    private refreshInvoiceNotifier = new Subject<any>();
    private refreshProviderInvoiceNotifier = new Subject<any>();
    private refreshQuoteNotifier = new Subject<any>();
    private refreshProviderNotifier = new Subject<any>();
    private refreshProductFamilyNotifier = new Subject<any>();
    private refreshDepositsNotifier = new Subject<any>();
    private notifyInitializeValue = new Subject<any>();
    private updatePopTableSize = new Subject<any>();
    private websocketDeconnected = new Subject<any>();
    private refreshTechnicienNotifier = new Subject<any>();
    private refreshInterventionsNotifier = new Subject<any>();
    private refreshVehicleNotifier = new Subject<any>();
    private resetInterventionsNotifier = new Subject<any>();
    private networkStatusNotifier = new Subject<any>();
    private companieChangedNotifier = new Subject<any>();
    private resetTechnicianNotifier = new Subject<any>();
    private privilegesProgressNotifier = new Subject<any>();
    private changeContextNotifier = new Subject<any>();

    updatePopTableSize$ = this.updatePopTableSize.asObservable();
    updateDataTable$ = this.updateDataTable.asObservable();
    updateProfilImgNotifier$ = this.updateProfilImg.asObservable();
    updateLogoImgNotifier$ = this.updateLogoImg.asObservable();
    updateLanguageNotifier$ = this.updateLanguage.asObservable();
    updateFolderListNotifier$ = this.updateFolderList.asObservable();
    updateFolderSelectedNotifier$ = this.updateFolderSelected.asObservable();
    updateProfile$ = this.updateProfile.asObservable();
    updateChangeClientAdmin$ = this.updateClientAdmin.asObservable();
    deleteFolderNotifier$ = this.deleteFolder.asObservable();
    notifyEmptyFolder$ = this.notifyEmptyFolder.asObservable();
    notifyAddressChanges$ = this.notifyAddressChanges.asObservable();
    importClients$ = this.importClients.asObservable();
    importProducts$ = this.importProducts.asObservable();
    connectWebSocket$ = this.connectWebSocket.asObservable();
    finishedImport$ = this.finishedImport.asObservable();
    notifyChangePermissions$ = this.notifyChangePermissions.asObservable();
    sidebarOpened$ = this.sidebarOpened.asObservable();
    adminLogoRedirection$ = this.adminLogoRedirection.asObservable();
    newFolderNotifier$ = this.newFolderNotifier.asObservable();
    renameFolderNotifier$ = this.renameFolderNotifier.asObservable();
    refreshProductsNotifier$ = this.refreshProductsNotifier.asObservable();
    refreshProviderInvoiceNotifier$ = this.refreshProviderInvoiceNotifier.asObservable();
    refreshClientNotifier$ = this.refreshClientNotifier.asObservable();
    refreshPriceListNotifier$ = this.refreshPriceListNotifier.asObservable()
    refreshFamilyCientNotifier$ = this.refreshFamilyCientNotifier.asObservable()
    refreshDeliveryNoteNotifier$ = this.refreshDeliveryNoteNotifier.asObservable()
    productCreatedNotifier$ = this.productCreatedNotifier.asObservable()
    changeAssociateDocsNotifier$ = this.changeAssociateDocsNotifier.asObservable();
    refreshInvoiceNotifier$ = this.refreshInvoiceNotifier.asObservable()
    refreshQuoteNotifier$ = this.refreshQuoteNotifier.asObservable()
    refreshProviderNotifier$ = this.refreshProviderNotifier.asObservable()
    refreshProductFamilyNotifier$ = this.refreshProductFamilyNotifier.asObservable()
    refreshDepositsNotifier$ = this.refreshDepositsNotifier.asObservable()
    notfiyInitializeValue$ = this.notifyInitializeValue.asObservable();
    websocketDeconnected$ = this.websocketDeconnected.asObservable();
    refreshTechnicienNotifier$ = this.refreshTechnicienNotifier.asObservable();
    refreshInterventionsNotifier$ = this.refreshInterventionsNotifier.asObservable();
    refreshVehicleNotifier$ = this.refreshVehicleNotifier.asObservable();
    resetInterventionsNotifier$ = this.resetInterventionsNotifier.asObservable();
    networkStatusNotifier$ = this.networkStatusNotifier.asObservable();
    companieChangedNotifier$ = this.companieChangedNotifier.asObservable();
    resetTechnicianNotifier$ = this.resetInterventionsNotifier.asObservable();
    privilegesProgressNotifier$ = this.privilegesProgressNotifier.asObservable();
    changeContextNotifier$ = this.changeContextNotifier.asObservable();

    // send when associate docs from other to reload new data from backend
    changeAssociateDocs(id) {
        this.changeAssociateDocsNotifier.next(id);
    }

    // send from popUp to create attachements (folder, employeeAttachement)
    newFileFolderNotifier(data) {
        this.newFolderNotifier.next(data);
    }
    // send from popUp to update folder attachements (folder, employeeAttachement)
    updateFileFolderNotifier(data) {
        this.renameFolderNotifier.next(data);
    }
    // send notifier when redirect form admin
    adminLoginRedirect() {
        this.adminLogoRedirection.next();
    }

    // notify when change folder
    notifyChangePermission() {
        this.notifyChangePermissions.next();
    }
    // Declanche after finished import
    updateAfterFinishedImport(data?) {
        this.finishedImport.next(data);
    }

    // declanche when deleting multi to reinitialize counter of selected elements
    updateDataTables() {
        this.updateDataTable.next();
    }

    notifyConnectWebSocket() {
        this.connectWebSocket.next();
    }

    completeImportClients(event, receivedRows?) {
        this.importClients.next(event);
        // this.importClients.next({event,receivedRows});
    }
    completeImportProducts(event, receivedRows?) {
        this.importProducts.next(event);
    }


    notifyAddressChange(event) {
        this.notifyAddressChanges.next(event);
    }

    notificationEmptyFolder(event) {
        this.notifyEmptyFolder.next(event);
    }


    deleteSelectedFolder(id: any) {
        this.deleteFolder.next(id);
    }

    updateChangeAdminClient(event) {
        this.updateClientAdmin.next(event);
    }

    updateUserProfile(data) {
        this.updateProfile.next(data);
    }
    /**
     * notify the update of the profil image
     */
    updateImg() {
        this.updateProfilImg.next();
    }
    /**
     * notify the update of the logo image
     */
    updateLogo(attachementId) {
        this.updateLogoImg.next(attachementId);
    }

    /**
     * notify the update of folders list
     */
    updateFolderListFunc(folder?) {
        this.updateFolderList.next(folder);
    }

    /**
     * notify the update of folder selection
     */
    updateFolderSelectedFunc() {
        this.updateFolderSelected.next();
    }

    /**
     * notify the language change
     */
    updateLang(lang) {
        this.updateLanguage.next(lang);
    }

    refreshDashboard() {
        this.sidebarOpened.next();
    }

    refreshDatatable() {
        this.sidebarOpened.next();
    }

    refreshProducts(data?) {
        this.refreshProductsNotifier.next(data);
    }
    refreshProviderInvoice(data?) {
        this.refreshProviderInvoiceNotifier.next(data)
    }
    refreshClients(data?) {
        this.refreshClientNotifier.next(data);

    }
    refreshTechnicien(data?) {
        this.refreshTechnicienNotifier.next(data);

    }
    refreshInterventions(data?) {
        this.refreshInterventionsNotifier.next(data);

    }
    refreshVehicle(data?) {
        this.refreshVehicleNotifier.next(data);

    }
    refreshPriceList(data?) {
        this.refreshPriceListNotifier.next(data);
    }

    refreshFamilyClient(data?) {
        this.refreshFamilyCientNotifier.next(data);
    }

    refreshDeliveryNote(data?) {
        this.refreshDeliveryNoteNotifier.next(data);
    }
    refreshInvoices(data?) {
        this.refreshInvoiceNotifier.next(data);
    }
    refreshQuotes(data?) {
        this.refreshQuoteNotifier.next(data);

    }
    refreshProviders(data?) {
        this.refreshProviderNotifier.next(data);

    }
    refreshProductFamily(data?) {
        this.refreshProductFamilyNotifier.next(data);
    }
    refreshDeposit(data?) {
        this.refreshDepositsNotifier.next(data)
    }
    notifyEmployeeChange() {
        this.notifyInitializeValue.next();
    }
    updatePopTableSizes(data: any) {
        this.updatePopTableSize.next(data);
    }
    // notifi websocket disconnected status
    websocketDeconnectedStatus(data) {
        this.websocketDeconnected.next(data);
    }
    resetInterventions() {
        this.resetInterventionsNotifier.next(null);
    }
    resetTechnician() {
        this.resetTechnicianNotifier.next(null);
    }
    updateNetworkStatus(data) {
        this.networkStatusNotifier.next(data);
    }
    companieChangedStatus() {
        this.companieChangedNotifier.next(true);
    }
    privilegesProgressStatus(status) {
        this.privilegesProgressNotifier.next(status);
    }
    changeContext(status) {
        this.changeContextNotifier.next(status);
    }
}
