/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable prefer-const */
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'app/services/common.service';
import { PopupSirh } from 'app/shared/components/popUps/popUp/popup-sirh.component';
import { NotifiersService } from './services/notifiers.service';
import { WebsocketService } from './services/websocket.service';
import { StorageUtils } from './shared/storage-utils';
import { AuthService } from './services/auth.service';
import { Component, Inject, isDevMode, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { navigation } from 'app/navigation/navigation';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { locale as navigationFrancais } from 'app/navigation/i18n/fr';
import { locale as navigationArabe } from 'app/navigation/i18n/dz';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseNavigation } from '@fuse/types';
import { adminNavigation } from './navigation/admin-navigation';
import { SIRHLoaderComponent } from './shared/components/sirh-loader/sirh-loader.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VersionCheckService } from './services/version-check.service';
import { UtilsService } from './services/utils.service';
import { FolderService } from './services/folder.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { interval } from 'rxjs';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    url: any;
    login: boolean;
    alertOpt: SweetAlertOptions = {};
    // Private
    private _unsubscribeAll: Subject<any>;
    //   public SIRHLoaderComponent = LoaderComponent;
    adminNavigation: FuseNavigation[];
    public SIRHLoaderComponent = SIRHLoaderComponent;
    secondsBeforeReloadInterval: any;
    secondsBeforeReload = 10;
    // this will be replaced by actual version post-build.js
    currentVersion = '0.0.0';
    reloadPopupRef: MatDialogRef<any>;
    @ViewChild('reloadPopup')
    reloadPopup: TemplateRef<any>;
    statesReferential: boolean;
    comunesReferential: boolean;
    LegalFormsReferential: boolean;
    ActivitiesReferential: boolean;
    FoldersCountReferential: boolean;
    payementTermsReferential: boolean;
    unitMeasureReferential: boolean;
    tvaReferential: boolean;
    browserVersion: string;
    browserName: string;
    mySubscription: Subscription
    private subscription: Subscription;
    authUrl: any;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private authService: AuthService,
        public router: Router,
        public sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private versionCheckService: VersionCheckService,
        private utilService: UtilsService,
        private folderService: FolderService,
        private webSocketService: WebsocketService,
        private notifierService: NotifiersService,
        private commonService: CommonService,
        private httpClient: HttpClient,
    ) {
        /** if we are in login path & the user is not connected then set login to true */
        this.router.events.forEach(event => {
            if (event instanceof NavigationStart && (event.url.includes('/login') || event.url.includes('/guest-register') ||
                event.url.includes('/activate-account') || event.url.includes('/reset-password')) && !StorageUtils.getAuthToken()) {
                this.authService.getAuthUrl().subscribe(res => {
                    if (event.url.includes('/login')) {
                        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(location.hostname.includes('test') ?
                            'https://auth.tech-instinct.com/login' : res.authUrl);
                        this.authUrl = location.hostname.includes('test') ?
                            'https://auth.tech-instinct.com/login' : res.authUrl;
                    } else if (event.url.includes('/guest-register') || event.url.includes('/activate-account') || event.url.includes('/reset-password')) {
                        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(location.hostname.includes('test') ?
                            'https://auth.tech-instinct.com' + event.url : res.authUrl + event.url);
                        this.authUrl = location.hostname.includes('test') ?
                            'https://auth.tech-instinct.com' + event.url : res.authUrl + event.url;
                    }
                    this.login = true;
                    this.authManegement();
                });
            } else if (event instanceof NavigationStart && (event.url.includes('/login') || event.url.includes('/guest-register') ||
                event.url.includes('/activate-account') || event.url.includes('/reset-password')) && StorageUtils.getAuthToken()) {
                this.router.navigate(['']);
            }
        });
        // this.mySubscription= interval(7000).subscribe((x =>{
        //     this.detectTranslateBrowser()
        // }));
        // if there is no refs then get it
        this.router.events.forEach(event => {
            if (event instanceof NavigationStart) {
                if (StorageUtils.getAuthToken()) {
                    this.getReferencials();
                }
            }
        });

        this.authService.getCSRFToken().subscribe();
        // get authentification url by platform

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Get admin navigation
        this.adminNavigation = adminNavigation;

        // Register the admin navigation to the service
        this._fuseNavigationService.register('admin', this.adminNavigation);

        // Set the main navigation as our current navigation
        const context = StorageUtils.getContext();
        this._fuseNavigationService.setCurrentNavigation(context === null ? 'main' : context);

        // Add languages
        this._translateService.addLangs(['fn', 'd']);

        // Set the default language
        this._translateService.setDefaultLang('fr');

        // Set the navigation translations
        this.fuseTranslationLoaderService.loadTranslations(
            navigationFrancais,
            navigationArabe
        );

        // Use a language
        this._translateService.use('fr');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (StorageUtils.getAssistanceValue() === null) {
            StorageUtils.setAssistanceValue(true);
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        //check if there another new version then show msg 
        if (!isDevMode()) {
            this.initVersionCheck();
        }
        this.browserName = this.detectBrowserName();
        this.browserVersion = this.detectBrowserVersion();
        this.detectOutdateBrowser();

        //connect websocket
        const authToken = StorageUtils.getAuthToken();
        if (authToken) {
            this.webSocketService.connect();
        }
        this.subscription = this.notifierService.connectWebSocket$.subscribe(res => {
            this.webSocketService.connect();
        })

    }

    // detectTranslateBrowser(){
    //     if(document.getElementsByTagName('html')[0].getAttribute('lang') != (navigator.language).slice(0,2)){
    //        const message = 'Attention ! La traduction de la page peut provoquer des erreurs';
    //        Swal.fire({           
    //         position: 'top',
    //         text: message,
    //         showConfirmButton: true,
    //         type: 'warning',
    //         toast: false,
    //         allowOutsideClick: false,
    //         width:400, 
    //         confirmButtonColor:'#3fa9ff'
    //      }).then((result) => {
    //         if (result.value) {
    //           this.mySubscription.unsubscribe()
    //         } })
    //    }
    // }      
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    alertBrowserOutDate() {
        const message = `Votre version de ${this.browserName} n'est pas compatible, vous risquez de croiser des difficulté.\
        Veuillez mettre a jour ${this.browserName}.`;
        Swal.fire({
            position: 'top',
            text: message,
            showConfirmButton: true,
            toast: false,
            allowOutsideClick: false,
            width: '400rem',
            icon: 'warning',
        })
    }

    detectOutdateBrowser() {
        if (this.browserName === 'chrome') {
            if (Number(this.browserVersion.slice(7, 10)) < 99) {
                this.alertBrowserOutDate()
            }
        } else if (this.browserName === 'firefox') {
            if (Number(this.browserVersion.slice(8, 10)) < 90) {
                this.alertBrowserOutDate()
            }
        } else if (this.browserName === 'edge') {
            if (Number(this.browserVersion.slice(5, 7)) < 90) {
                this.alertBrowserOutDate()
            }
        }
    }

    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    detectBrowserVersion() {
        let userAgent = navigator.userAgent, tem,
            matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }

        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }

        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];

        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
        return matchTest.join(' ');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    initVersionCheck(frequency = 1000 * 60 * 5) {
        setInterval(() => {
            this.versionCheckService.checkVersion().subscribe((response: any) => {
                const version = response.version;
                const versionChanged = this.versionChanged(this.currentVersion, version);
                // If new version, do something
                if (versionChanged) {
                    this.reloadPage();
                } else { this.currentVersion = version; }
            },
                (err) => {
                    // TODO
                }
            );
        }, frequency);
    }

    versionChanged(currentVersion, newversion) {
        if (!currentVersion || currentVersion === '0.0.0') {
            return false;
        }
        return currentVersion !== newversion;
    }

    reloadPage() {
        this.reloadPopupRef = this.dialog.open(this.reloadPopup, { disableClose: true });
        this.getTimeBeforeReload();
    }

    reload() {
        location.reload();
    }

    getTimeBeforeReload() {
        this.secondsBeforeReloadInterval = setInterval(() => {
            if (this.secondsBeforeReload === 0) {
                this.reloadPopupRef.close();
                this.reload();
            } else {
                this.secondsBeforeReload--;
            }
        }, 1000);
    }

    authManegement() {
        window.onmessage = (event) => {
            if (this.authUrl && this.authUrl.split('.').length > 2 &&
                window.location.href && window.location.href.split('.').length > 2
                && this.authUrl.split('.')[1] === window.location.href.split('.')[1]
                && event.data.name === 'token') {  // ajouter une condition sur l'origine de l'information.
                // StorageUtils.setConnect('true');
                const token = event.data.token;
                localStorage.clear();
                StorageUtils.setAuthToken(token);
                this.getReferencials();
                // verify if there is folder for this account
                this.getFoldersCount();
            }
        };
    }

    async getReferencials() {
        await this.getWilaya();
        await this.getCommunes();
        await this.getActivities();
        await this.getLegalForms();
        await this.getPayementTerms();
        await this.getMesureUnit();
        await this.getTva();
    }

    getPayementTerms() {
        if (StorageUtils.getPayementTerm().length === 0) {
            this.utilService.getPayementTerms().subscribe(payementTerms => {
                StorageUtils.setPayementTerms(payementTerms);
                this.payementTermsReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }
    getWilaya() {
        let wilayas: any[];
        if (StorageUtils.getWilayas().length === 0) {
            this.utilService.getAllWilaya().subscribe(res => {
                wilayas = res;
                wilayas.sort((a, b) => a.code < b.code ? -1 : a.code > b.code ? 1 : 0);
                StorageUtils.setWilayas(wilayas);
                this.statesReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }

    getCommunes() {
        if (StorageUtils.getCommunes().length === 0) {
            this.utilService.getAllCommunes().subscribe(res => {
                res.forEach(resp => {
                    resp.name = resp.name.toLowerCase();
                    resp.name = resp.name.charAt(0).toUpperCase() + resp.name.slice(1);
                });
                StorageUtils.setCommunes(res);
                const CommunesByWilaya = res.reduce((r, a) => {
                    r[a.wilayaId] = [...r[a.wilayaId] || [], a];
                    return r;
                }, {});
                StorageUtils.setCommunesByWilaya(CommunesByWilaya);
                this.comunesReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }

    getLegalForms() {
        if (StorageUtils.getLegalForms().length === 0) {
            this.utilService.getAllLegalForms().subscribe(LegalForms => {
                StorageUtils.setLegalForms(LegalForms);
                this.LegalFormsReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }

    getActivities() {
        if (StorageUtils.getActivities().length === 0) {
            this.utilService.getActivities().subscribe(activities => {
                StorageUtils.setActivities(activities);
                this.ActivitiesReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }

    getFoldersCount() {
        this.folderService.getEmptyFolders().subscribe(resp => {
            // there is not folder
            if (resp.length === 0) {
                StorageUtils.setEmptyFolder(true);
            }
            this.FoldersCountReferential = true;
            this.verifyThatWeGetAllReferencials();
        });
    }

    verifyThatWeGetAllReferencials() {
        if (this.statesReferential && this.comunesReferential && this.LegalFormsReferential
            && this.ActivitiesReferential && this.tvaReferential && this.unitMeasureReferential &&
            this.payementTermsReferential && this.FoldersCountReferential) {
            this.resetReferentielsVariables();
            window.location.reload();
        }
    }

    resetReferentielsVariables() {
        this.statesReferential = false;
        this.comunesReferential = false;
        this.LegalFormsReferential = false;
        this.ActivitiesReferential = false;
        this.FoldersCountReferential = false;
        this.tvaReferential = false;
        this.unitMeasureReferential = false;
    }

    getMesureUnit() {
        if (StorageUtils.getUnitMeasure().length === 0) {
            this.utilService.getMesureUnit().subscribe(res => {
                StorageUtils.setUnitMeasure(res);
                this.unitMeasureReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }

    getTva() {
        if (StorageUtils.getTva().length === 0) {
            this.utilService.getTva().subscribe(res => {
                StorageUtils.setTva(res);
                this.tvaReferential = true;
                this.verifyThatWeGetAllReferencials();
            });
        }
    }

}



